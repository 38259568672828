<template>
  <div style="width: 65%; justify-content: center; margin: 0 auto;">
    <md-card>
      <md-card-header>
        <div class="md-layout md-size-90 md-alignment-center">
          <p class="md-headline">
            Privacy Policy of All My Faves Inc. / Updated: 17/06/2023
          </p>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-90 md-alignment-center">
          <div class="md-layout-item md-size-90 md-small-size-90">
            <p>
              At All My Faves, we value your privacy and are committed to protecting your personal information. This
              Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website. We may
              collect information through log files, use cookies for improved functionality and personalized advertising,
              and participate in affiliate programs. We work to ensure the security and confidentiality of your data and
              provide options to manage your preferences. Please read the full Privacy Policy below for detailed
              information on how we handle your personal information.
            </p>
            <p>
              All My Faves Inc. recognizes the importance of privacy and is committed to protecting your personal
              information. Please read the following information to understand our privacy policy guidelines. As we
              continue to make additions and expansions to our website and given the evolving nature of Internet
              technologies, these guidelines are subject to change. All My Faves reserves the right to make changes at its
              discretion, which will be posted on this page.
            </p>
            <p>
              Please note that All My Faves Inc. is not responsible for the content or privacy policies of websites linked
              from our site.
            </p>
            <p>
              Our principal activity is providing a visual portal and bookmark manager.
            </p>
            <p>
              This privacy policy applies to All My Faves Inc. and its website:
            </p>
            <table width="350" border="1" cellspacing="0">
              <tbody>
                <tr bordercolor="white">
                  <td width="140" border="0"><b> Organization Name: </b></td>
                  <td border="0"><b> All My Faves Inc.</b></td>
                </tr>

                <tr bordercolor="white">
                  <td width="140" border="0"><b> City: </b></td>
                  <td border="0"><strong> Petah Tikva, Mivtza Dekel 6 </strong></td>
                </tr>

                <tr bordercolor="white">
                  <td width="140" border="0"><b> Country: </b></td>
                  <td border="0"><strong>Israel </strong></td>
                </tr>
                <tr bordercolor="white">
                  <td width="140"><b> Websites (including all international domains): </b></td>
                  <td border="0"><b> <a src="https://www.allmyfaves.com">https://www.allmyfaves.com</a></b></td>
                </tr>
              </tbody>
            </table>
            <p>
              At All My Faves, we understand the importance of your privacy and the security of your personal information.
              This section outlines the types of personal information we collect and maintain in our company's records
              when you use or visit All My Faves, and how we protect that information.
            </p>
            <p class="md-subheading bold">
              Collection and Use of Personally Identifiable Information
            </p>
            <p>
              All My Faves collects and uses personally identifiable information solely for the following purposes:
              providing users with the services available through allmyfaves.com, communicating with users, and managing
              registered user accounts, if applicable.
            </p>
            <p class="md-subheading bold">
              Website Usage Information
            </p>
            <p>
              As with most other websites, our servers automatically record information that your browser sends whenever
              you visit a website ("Log Data"). The information in the log files may include your IP address (Internet
              Protocol), your ISP (Internet Service Provider, such as AOL or Shaw Cable), the browser through which you
              visit our site (such as Chrome or Firefox), the time at which you visited our site, and which pages you
              visited within the entire All My Faves site.
            </p>
            <p>
              We also use services such as Google Analytics and Microsoft Clarity to analyze the usage of our site and
              improve its functionality. These services may collect additional information such as your device
              information, operating system, and user interactions on the site. The data collected by these services is
              used for statistical analysis and to enhance user experience.
            </p>
            <p class="md-subheading bold">
              Cookies and Web Beacons
            </p>
            <p>
              All My Faves uses cookies to enhance your experience on our site and for advertising purposes. Cookies are
              small text files that are placed on your computer or device by websites that you visit. They are widely used
              to make websites work more efficiently and to provide a personalized browsing experience.
            </p>
            <p>
              We use cookies to store information such as your personal preferences and browsing behavior when you visit
              our site. These cookies help us deliver targeted advertisements, track user engagement, and analyze
              advertising campaign effectiveness. They also enable us to customize the content, layout, and services of
              the All My Faves website.
            </p>
            <p>
              Additionally, we may work with third-party advertising partners who may also set cookies on your device to
              provide personalized advertisements and track their performance. These cookies collect information about
              your visits to our site and other websites, such as the pages you have viewed, the links you have clicked,
              and your browsing activities. This information is used to tailor advertisements to your interests and serve
              relevant content.
            </p>
            <p>
              By using the All My Faves website, you consent to the use of cookies for advertising purposes. You can
              manage your cookie preferences and opt-out of targeted advertising by adjusting your browser settings or by
              using third-party cookie opt-out mechanisms.
            </p>
            <p>
              Please note that disabling or blocking cookies may impact your ability to access certain features or
              functionality of the All My Faves website.
            </p>
            <p class="md-subheading bold">
              Information Collected by All My Faves
            </p>
            <p>
              While using AllMyFaves.com is free for everyone, registration is required to access certain services and
              features, such as the personalized Faves feature. As a registered user, you will be asked to provide
              personally identifiable information, including your username, password, email address, and any other details
              you choose to submit or include on your personalized page.
            </p>
            <p>
              By adding sites to your personalized All My Faves homepage, you grant All My Faves and others permission to
              access and use them in connection with All My Faves and its affiliates and businesses. You can mark content
              as private to restrict access to those users to whom you explicitly grant access. All My Faves' right to
              access and use the content is not affected by your use of the content.
            </p>
            <p class="md-subheading bold">
              Use of Contact Information
            </p>
            <p>
              We may use your contact information to send you updates and promotions regarding current and future All My
              Faves features and services. If you wish to discontinue receiving such information, you can unsubscribe
              through the updates and promotional messages sent to your email or on the websites settings page.
            </p>
            <p class="md-subheading bold">
              Service Providers
            </p>
            <p>
              All My Faves has agreements with external service providers and other reliable third parties for the
              management and maintenance of our website. These include hosting and maintenance providers, database storage
              and management services, and marketing projects. Your personally identifiable information may be shared with
              these third parties on a limited basis for the sole purpose of performing and providing AllMyFaves.com's
              services and features. Any sharing of personally identifiable information with these third parties will be
              done after they have committed to safeguarding your privacy and ensuring the security of your data.
            </p>
            <p class="md-subheading bold">
              Confidentiality
            </p>
            <p>
              Feedback, comments, and statements you choose to submit to All My Faves by any means, such as email,
              telephone, letter, or other communication methods, will be considered non-confidential. All My Faves is free
              to use this information on an unrestricted basis.
            </p>
            <p class="md-subheading bold">
              Use of Information
            </p>
            <p>
              We use the information collected from users to improve our marketing and promotional efforts, analyze site
              usage statistics, enhance content and product offerings, and customize the content, layout, and services of
              the All My Faves website for the benefit of our users. We may share certain aggregate information based on
              analysis with our partners, customers, advertisers, or potential users. We may also use the collected
              information to execute marketing campaigns, promotions, or advertising messages on behalf of third parties.
              Information submitted through the "Tell a Friend" feature is collected and used in the mail sent to the
              referred person or friend listed in the submission.
            </p>
            <p class="md-subheading bold">
              Links to Other Sites
            </p>
            <p>
              All My Faves provides numerous links to external websites. However, these links do not constitute
              endorsement, advertising for profit, authorization, or representation of affiliation with third parties or
              featured sites, except for sites and parties with which All My Faves has a contracted agreement. All My
              Faves has no control over the content, cookies, data collection practices, or personally identifiable
              information solicited by the featured sites. Each site operates under its own rules regarding the use or
              disclosure of personally identifiable information. We recommend consulting the privacy policies and/or
              statements of the websites you visit.
            </p>
            <p class="md-subheading bold">
              All My Faves' Policy Regarding Children
            </p>
            <p>
              AllMyFaves.com is intended for use by individuals aged 13 years or older. Children under 13 may use the
              website under the supervision of their parents, legal guardians, or authorized adults. However, children may
              not provide any personally identifiable information to AllMyFaves.com. If you are a parent or legal guardian
              who learns that a child under your care has provided personally identifiable information without your
              knowledge or consent, please contact us at info@allmyfaves.com. AllMyFaves.com does not knowingly collect
              personally identifiable information from children under 13, and any such information will be immediately
              removed from our records.
            </p>
            <p class="md-subheading bold">
              Delete Your Account
            </p>
            <p>
              To delete your account and remove your content, follow these steps:
            </p>
            <ol>
              <li>
                Click on the "Settings" tab located at the top right of the site.
              </li>
              <li>
                Locate the "Delete Account" button on the main page.
              </li>
              <li>
                Click on the button and follow the prompts to complete the deletion process.
              </li>
            </ol>
            <p>
              If you encounter any issues locating your account or have any deletion requests, please email
              info@allmyfaves.com for assistance. Note that account deletion is permanent and cannot be undone. Please
              ensure that you have downloaded any critical data or content before proceeding with account deletion.
            </p>
            <p class="md-subheading bold">
              Your Feedback
            </p>
            <p>
              All My Faves welcomes your continuous input regarding our Terms of Use, Privacy Policy, or the services we
              provide. Please send your comments and responses to info@allmyfaves.com.
            </p>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
};
</script>
